import Logo from "../../../images/logo_200x200.png";
import config from "../../../config";
import { Container, Divider, Typography } from "@mui/material";

export default function() {
    return (

        <Container sx={{textAlign: "center"}}>

            <a href={config.website.url} target="_blank" rel="noopener">
                <img src={Logo} width={50} height={50}></img>
            </a>
            <Typography variant={"h5"} sx={{m: 1}}>NPK of Everything</Typography>
            
        </Container>
            

    )
}