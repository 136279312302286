export default {
    db: {
        url: process.env.DB_URL
    },
    ga: {
        id: process.env.GA_ID
    },
    website: {
        url: process.env.WEBSITE_URL
    }
}