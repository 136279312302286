import { CircularProgress, Stack, Typography, Button, Container } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// TODO switch to serverside paginated DataGrid

const round = (num, degree=2) => {
    let factor = 10 ** degree
    return Math.round(num * factor) / factor
} 

export default ({fertilizers, skip, setSkip, limit, total, isLoading}) => {
    if(isLoading) {
        return (
            <Stack alignItems={"center"} sx={{m: 5}}>
                <CircularProgress />
            </Stack>
        )
    }
    return (
        <Container sx={{textAlign: "center"}}>
            <TableContainer sx={{m: 1}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Nitrogen (N)</TableCell>
                            <TableCell>Phosphorus (P)</TableCell>
                            <TableCell>Potassium (K)</TableCell>
                            <TableCell>Source</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fertilizers.map((fert) => {
                            return (
                                <TableRow key={fert.name}>
                                    <TableCell>{fert.name}</TableCell>
                                    <TableCell>{round(fert.nitrogen)}</TableCell>
                                    <TableCell>{round(fert.phosphorus)}</TableCell>
                                    <TableCell>{round(fert.potassium)}</TableCell>
                                    <TableCell><a href={fert.source.url} target="__blank">{fert.source.name}</a></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography>Results {skip + 1} - {Math.min(skip + Number(limit) + 1, total)} out of {total}</Typography>
            <Button onClick={() => {
                let newSkip = skip - limit;
                if(newSkip < 0) {
                    newSkip = 0;
                }
                setSkip(newSkip);
            }}>Previous Page</Button>

            <Button onClick={() => {
                let newSkip = skip + Number(limit);
                if(newSkip < total) {
                    setSkip(newSkip);
                }
            }}>Next Page</Button>
        </Container>  
    )
}