import { useState, useEffect } from "react";
import FertilizersOutput from "./FertilizersOutput";
import FertilizersInputs from "./FertilizersInputs";
import { Container } from "@mui/material";
import { getFertilizers, getTotal, getSources } from "../../api";

// TODO use range hooks instead of individual min max hooks for nutrients



export default function Table() {
    const [fertilizers, setFertilizers] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("nameLength");
    const [direction, setDirection] = useState("asc");
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);

    const [nmin, setNmin] = useState(0);
    const [nmax, setNmax] = useState(100);

    const [pmin, setPmin] = useState(0);
    const [pmax, setPmax] = useState(100);

    const [kmin, setKmin] = useState(0);
    const [kmax, setKmax] = useState(100);

    const [sources, setSources] = useState([]);

    const [isLoading, setIsLoading] = useState(false)
    const [timeoutRef, setTimeoutRef] = useState(null)

    useEffect(() => getSources().then(srcs => setSources(srcs.map(src => {
        src.selected = true;
        return src;
    }))), []);

    useEffect(() => {
        setIsLoading(true)
        
        if(timeoutRef) {
            clearTimeout(timeoutRef)
        }

        let tor = setTimeout(async () => {
            let ferts = await getFertilizers(sources, search, sort, direction, skip, limit, nmin, nmax, pmin, pmax, kmin, kmax)
            let tot = await getTotal(sources, search, nmin, nmax, pmin, pmax, kmin, kmax)

            if(tot != total) {
                setSkip(0)
            }

            setFertilizers(ferts);
            setTotal(tot)

            setIsLoading(false)
        }, 1000)

        setTimeoutRef(tor)
    }, [sources, search, sort, direction, skip, limit, nmin, nmax, pmin, pmax, kmin, kmax]);


    return (
        <Container>
            <FertilizersInputs
                setSearch={setSearch}
                setSort={setSort}
                setDirection={setDirection}
                limit={limit}
                setLimit={setLimit}
                nmin={nmin}
                setNmin={setNmin}
                nmax={nmax}
                setNmax={setNmax}
                pmin={pmin}
                setPmin={setPmin}
                pmax={pmax}
                setPmax={setPmax}
                kmin={kmin}
                setKmin={setKmin}
                kmax={kmax}
                setKmax={setKmax}
                sources={sources}
                setSources={setSources}
            />
            
            <FertilizersOutput 
                fertilizers={fertilizers}
                skip={skip}
                setSkip={setSkip}
                limit={limit}
                total={total}
                isLoading={isLoading}
            />
            
        </Container>  
    )
}