import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

export default ({label, options, onChange}) => {
    return (
        <FormControl fullWidth sx={{m:1}}>
            <InputLabel>{label}</InputLabel>
            <Select 
                label={label} 
                onChange={onChange} 
                defaultValue={options[0].value} 
                
            >
                {options.map(option => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
            </Select>
        </FormControl>
    )
}