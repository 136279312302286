import config from "../config";

const baseUrl = config.db.url

async function fetchCache(url) {
    let cachedResp = localStorage.getItem(url);
    if(cachedResp == null) {
        let resp = await (await fetch(url)).json();
        localStorage.setItem(url, JSON.stringify(resp));
        return resp;
    }
    else {
        return JSON.parse(cachedResp);
    }
}

export async function getFertilizers(sources, search="", sort="name", direction="asc", skip=0, limit=10, nmin=0, nmax=100, pmin=0, pmax=100, kmin=0, kmax=100) {
    return (await fetchCache(`${baseUrl}/v1/fertilizers?${sources.length == 0 ? "" : `sources=${sources.filter(src => src.selected).map(src => src._id).join(",")}&`}sort=${sort}&direction=${direction}&skip=${skip}&limit=${limit}${search != "" ? `&search=${search}`: ""}&nmin=${nmin}&nmax=${nmax}&pmin=${pmin}&pmax=${pmax}&kmin=${kmin}&kmax=${kmax}`)).map(fert => {
        if(sources.length > 0) {
            fert.source = sources.find(src => src._id == fert.source);
        }
        
        return fert;
    });
}

export async function getTotal(sources, search="", nmin=0, nmax=100, pmin=0, pmax=100, kmin=0, kmax=100) {
    return (await fetchCache(`${baseUrl}/v1/fertilizers/total?${sources.length == 0 ? "" : `sources=${sources.filter(src => src.selected).map(src => src._id).join(",")}&`}${search != "" ? `search=${search}&`: ""}nmin=${nmin}&nmax=${nmax}&pmin=${pmin}&pmax=${pmax}&kmin=${kmin}&kmax=${kmax}`)).total;
    
}

export function getSources() {
    return fetchCache(`${baseUrl}/v1/sources`);
}
