import SliderComponent from "../general/SliderComponent"

export default ({label, min, max, setMin, setMax}) => {
    return (
        <SliderComponent 
            label={label}
            value={[min, max]}
            min={0}
            max={100}
            onChange={event => {
                let [min, max] = event.target.value
                setMin(min)
                setMax(max)
            }}
        />
    )
}