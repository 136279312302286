import { TextField, Checkbox, FormGroup, FormControlLabel, Typography, Container, Grid } from "@mui/material";
import FertilizersNutrientSlider from "./FertilizersNutrientSlider";
import SelectComponent from "../general/SelectComponent"
import SliderComponent from "../general/SliderComponent";

export default ({
    setSearch,
    setSort,
    setDirection,
    limit,
    setLimit,
    nmin,
    setNmin,
    nmax,
    setNmax,
    pmin,
    setPmin,
    pmax,
    setPmax,
    kmin,
    setKmin,
    kmax,
    setKmax,
    sources,
    setSources
}) => {
    return (
        <Container sx={{m:1}}>
            <Grid container>
                <Grid xs={12}>
                    <TextField 
                        label={"Search"}
                        placeholder={"Search"}
                        onChange={event => setSearch(event.target.value)}
                        sx={{m:1}}
                        fullWidth
                    />
                </Grid>
                <Grid xs={4}>
                    
                    <SelectComponent 
                        label={"Sort"}
                        options={[
                            {value: "nameLength", label: "Name Length"}, 
                            {value: "name", label: "Name"},
                            {value: "nitrogen", label: "Nitrogen (N)"},
                            {value: "phosphorus", label: "Phosphorus (P)"},
                            {value: "potassium", label: "Potassium (K)"},
                            {value: "sources", label: "Sources"}
                        ]}
                        onChange={event => setSort(event.target.value)}
                    /> 

                    <SelectComponent 
                        label={"Direction"}
                        options={[
                            {value: "asc", label: "Ascending"},
                            {value: "desc", label: "Descending"}
                        ]}
                        onChange={event => setDirection(event.target.value)}
                    />
                    
                    <SliderComponent 
                        label={"Limit"}
                        value={limit}
                        min={1}
                        max={100}
                        onChange={event => setLimit(event.target.value)}
                    />
                </Grid>
                <Grid xs={4}>
                    <FertilizersNutrientSlider 
                        label={"Nitrogen (N)"}
                        min={nmin}
                        max={nmax}
                        setMin={setNmin}
                        setMax={setNmax}
                    />

                    
                    <FertilizersNutrientSlider 
                        label={"Phosphorus (P)"}
                        min={pmin}
                        max={pmax}
                        setMin={setPmin}
                        setMax={setPmax}
                    />

                    
                    <FertilizersNutrientSlider 
                        label={"Potassium (K)"}
                        min={kmin}
                        max={kmax}
                        setMin={setKmin}
                        setMax={setKmax}
                    />
                </Grid>
                <Grid xs={4}>
                    <FormGroup>
                        {sources.map(src => (
                            <FormControlLabel 
                                control={<Checkbox key={src._id} defaultChecked={src.selected} onChange={event => {
                                    setSources(sources.map(s => {
                                        if(s._id == src._id) {
                                            s.selected = event.target.checked;
                                        }
                                        return s;
                                    }));
                                }} />}
                                label={(<a href={src.url}>{src.name}</a>)}
                            />
                            
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>







        </Container>
    )
}