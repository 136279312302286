import { Slider, InputLabel, Container } from "@mui/material"
import {useState} from "react"

export default ({label, value, min, max, onChange}) => {
    const [val, setVal] = useState(value)
    return (
        <Container>
            <InputLabel>{`${label}: ${Array.isArray(val) ? val.join("-") : val}`}</InputLabel>
            <Slider 
                value={val}
                min={min}
                max={max}
                onChange={event => {
                    setVal(event.target.value)
                    onChange(event)
                }}
                sx={{m:1}}
            />
        </Container>

    )
}